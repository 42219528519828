import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import Loader from '@whitecobalt/tungsten/Loader'
import Image from '@whitecobalt/tungsten/Image'
import branding from '@config/branding'
import { useThemeStore } from '@services/store/theme'
import './index.scss'

interface PageLoaderProps {
	showLogo?: boolean;
    className?: string;
}


const PageLoader: React.FunctionComponent<PageLoaderProps> = ({ showLogo = false, className, children }) => {
	const [theme] = useThemeStore()
    return (
        <Loader className={className} fixed active hasSpinner={false} noTransition>
			<div className="page-loader-content">
				{showLogo && <Image className='brand-logo' style={{maxHeight: 100, width: '100%'}} src="/media/logos/logo.png" fallbackSrc={branding.logo.auth} loaderProps={{hasSpinner: false}} alt="Logo" />}
				<Spinner animation="border" />
				{children}
			</div>
		</Loader>
    )
}

export default PageLoader
