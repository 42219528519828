import { CMSFieldType } from "@types";
import React, { useEffect } from 'react';
import { useCMSPage } from "../CMSPage";
import branding from "@config/branding";
import { WEBSITE_URL } from "@utils/constants";
import { useCMSFieldGroup } from "../CMSFieldGroup";

interface CMSFieldProps {
    identifier: string;
    cmsPageFieldTypeID: CMSFieldType;
    description: string;
    showDefault?: boolean;
    children: (value: any) => React.ReactNode;
}

const CMSField: React.FunctionComponent<CMSFieldProps> = ({ identifier, cmsPageFieldTypeID, description, showDefault, children }) => {
    const { groupIdent } = useCMSFieldGroup()
    const { cmsPageField, cmsPageFieldsPayload, setCMSPageFieldsPayload } = useCMSPage()

    useEffect(() => {
        if(cmsPageFieldsPayload.some((item) => item.identifier === identifier)) return;
        setCMSPageFieldsPayload((prev) => [
            ...prev,
            {
                "identifier": identifier,
                "groupIdent": groupIdent,
                "cmsPageFieldTypeID": cmsPageFieldTypeID,
                "description": description
            }
        ])

        return () => {
            setCMSPageFieldsPayload((prev) => prev.filter((item) => item.identifier !== identifier))
        }
    }, [identifier, cmsPageFieldTypeID, description, groupIdent])

    const value = cmsPageField[identifier]?.value || undefined

    if(!showDefault && !cmsPageField[identifier]?.value) return null

	return (
		<>
            {children(cmsPageFieldTypeID === CMSFieldType.Image ? `${branding.services.env === "production" ? "" : WEBSITE_URL}${value}` : value)}
		</>
	);
}

export default CMSField;