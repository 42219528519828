import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export const trackingkey = "trackingIdent"

export const useTracking = () => {
    const location = useLocation()

    useEffect(() => {
        const url = new URLSearchParams(location.search)
        const accessKey = url.get('tungstenid')  || url.get('Tungstenid') || url.get('accessKey') || url.get('AccessKey') || url.get('trackingIdent') || url.get('TrackingIdent')

        if(accessKey) {
            localStorage.setItem(trackingkey, accessKey)
        }
    }, [])

    const request = useAuthAPI('/public/websitepageview')

    useEffect(() => {
        const trackingIdent = localStorage.getItem(trackingkey)
        if(trackingIdent) {
            request.call({
                data: {
                    "trackingIdent": trackingIdent,
                    "url": window.location.href
                }
            })
        }
        
    }, [location.pathname])
}