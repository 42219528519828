import React, { createContext, useContext, useEffect } from 'react';
import { useCMSPage } from "../CMSPage";

const CMSFieldGroupContext = createContext<{
    groupIdent: string | null;
}>({ groupIdent: null})

export const useCMSFieldGroup = () => useContext(CMSFieldGroupContext)

interface CMSFieldGroupProps {
    identifier: string;
    description: string;
}

const CMSFieldGroup: React.FunctionComponent<CMSFieldGroupProps> = ({ identifier, description, children }) => {
    const { cmsPageField, cmsPageGroupsPayload, setCMSPageGroupsPayload } = useCMSPage()

    useEffect(() => {
        if(cmsPageGroupsPayload.some((item) => item.identifier === identifier)) return;
        setCMSPageGroupsPayload((prev) => [
            ...prev,
            {
                "identifier": identifier,
                "description": description
            }
        ])

        return () => {
            setCMSPageGroupsPayload((prev) => prev.filter((item) => item.identifier !== identifier))
        }
    }, [identifier, description])

	return (
		<CMSFieldGroupContext.Provider value={{ groupIdent: identifier }}>
            {children}
		</CMSFieldGroupContext.Provider>
	);
}

export default CMSFieldGroup;