import React, { Suspense, lazy } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import ConditionalRoute from '@whitecobalt/tungsten/ConditionalRoute';
import ErrorBoundary from '@whitecobalt/tungsten/ErrorBoundary';
import Loader from '@whitecobalt/tungsten/Loader'
import { useSession } from '@whitecobalt/tungsten/Session';
import branding from '@config/branding'
import ErrorPage404 from '../Error/404';
// import AuthContainer from './AuthContainer';
import Layout from '@components/Layout';
import Container from 'react-bootstrap/Container';
const  OrganistionSelection = lazy(() => import('./OrganistionSelection'))
const  Impersonation = lazy(() => import('./Impersonation'))
const  ConfirmEmail = lazy(() => import('./ConfirmEmail'))
const  Login = lazy(() => import('./Login'))
const  MFA = lazy(() => import('./MFA'))
const  Logout = lazy(() => import('./Logout'))
const  ForgotPassword = lazy(() => import('./ForgotPassword'))
const  PasswordReset = lazy(() => import('./PasswordReset'))

interface AuthRoutesProps {
    
}

const AuthRoutes: React.FunctionComponent<AuthRoutesProps> = () => {
    const [{ user, redirect, others }] = useSession();
    const hasAuthenthication = !!user
    
    const finalRedirection = branding.allowOrganisationSelection && !others.hasSelectedOrganisation ? "/auth/select-organisation" : redirect || branding.landingPath

    return (
        <ErrorBoundary>
            <Layout>
                <Container>
                    <Suspense fallback={(<Loader active />)}>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="w-sm-50 futuristic-card futuristic-card-xl my-3 bg-white p-5 border">
                                <Switch>
                                    <Redirect exact path="/auth" to="/auth/login"/>
                                    <ConditionalRoute
                                        condition={hasAuthenthication}
                                        redirectTo='/auth/login'
                                        path="/auth/logout"
                                        exact>
                                        <Logout />
                                    </ConditionalRoute>
                                    <ConditionalRoute
                                        condition={!hasAuthenthication}
                                        redirectTo={finalRedirection}
                                        path="/auth/login"
                                        exact>
                                        <Login />
                                    </ConditionalRoute>
                                    <ConditionalRoute
                                        condition={!hasAuthenthication}
                                        redirectTo={finalRedirection}
                                        path="/auth/mfa"
                                        exact>
                                        <MFA />
                                    </ConditionalRoute>
                                    <ConditionalRoute
                                        condition={!hasAuthenthication}
                                        redirectTo={finalRedirection}
                                        path="/auth/forgot-password"
                                        exact>
                                        <ForgotPassword />
                                    </ConditionalRoute>
                                    <ConditionalRoute
                                        condition={true}
                                        redirectTo={finalRedirection}
                                        path="/auth/password-reset"
                                        exact>
                                        <PasswordReset />
                                    </ConditionalRoute>
                                    {branding.allowOrganisationSelection && (
                                        <ConditionalRoute
                                            condition={hasAuthenthication && !others.hasSelectedOrganisation}
                                            redirectTo={redirect || branding.landingPath}
                                            path="/auth/select-organisation"
                                            exact>
                                            <OrganistionSelection />
                                        </ConditionalRoute>
                                    )}
                                    {branding.allowImpersonation && (
                                        <Route 
                                            path="/auth/impersonation/:originalToken/:impersonationToken/:originalOrgID/:url" 
                                            component={Impersonation}/>
                                    )}
                                    <Route
                                        path="/auth/confirm-email"
                                        exact
                                        component={ConfirmEmail}/>
                                    <Route path="*" component={ErrorPage404}/>
                                </Switch>
                            </div>
                        </div>
                    </Suspense>
                </Container>
            </Layout>
        </ErrorBoundary>
    )
}

export default AuthRoutes
