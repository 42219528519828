import React, { createContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI";
import { emptyArray } from "@whitecobalt/tungsten/esm/common/utils/assets";
import { CMSPayloadGroupType, CMSPayloadFieldType } from "./CMSPage";
import PageLoader from "@components/Loader";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { backgroundLoadPayloads } from "./backgroundloads";

type CMSPayloadDataType = {
    identifier: string,
    partialPage: boolean,
    url: string | null,
    fields: Array<CMSPayloadFieldType>,
    groups: Array<CMSPayloadGroupType>,
}

type CMSPageDataType = {
    identifier: string,
    partialPage: false,
    pageTitle: string,
    metaDescription: string,
    keywords: string,
    noSitemap: boolean,
    fields: Array<{
        identifier: string,
        value: string
    }>
}

type CMSResponseType = {
    success: boolean,
    cmsPages: CMSPageDataType[]
}

const CMSContext = createContext<{
    cmsPagesPayload: CMSPayloadDataType[];
    setCMSPagesPayload: React.Dispatch<React.SetStateAction<CMSPayloadDataType[]>>;
    cmsPages: CMSPageDataType[];
} | null>(null)

export const useCMS = () => {
    const cmsContext = React.useContext(CMSContext)
    if(!cmsContext) {
        throw new Error('useCMS must be used within a CMSProvider')
    }
    return cmsContext
}

interface CMSProvider {

}

const CMSProvider: React.FunctionComponent<CMSProvider> = ({ children }) => {
    const history = useHistory()
    const [cmsPagesPayload, setCMSPagesPayload] = useState<CMSPayloadDataType[]>([])
    const [loading, setLoading] = useState(false)
    const getCMSData = useAuthAPI<CMSResponseType>('/api/CMSPage/data')
    const cmsPagesDataCollection = useRef(new Map<string, CMSPageDataType[]>()).current

    const pathname = history.location.pathname

    useEffect(() => {
        if(cmsPagesDataCollection.has(pathname)) return;

        const controller = new AbortController()
        setLoading(true)
        const cleanup = setTimeout(() => {
            getCMSData.call({
                data: {
                    cmsPages: cmsPagesPayload,
                },
                signal: controller.signal
            })
            .then((response) => {
                cmsPagesDataCollection.set(pathname, response.data.cmsPages)
                setLoading(false)
            })
        }, 300)

        return () => {
            controller.abort()
            clearTimeout(cleanup)
        }
    }, [cmsPagesPayload, pathname])

    useLayoutEffect(() => {
        if(!loading) return;

        document.body.classList.add('overflow-hidden')
        return () => {
            document.body.classList.remove('overflow-hidden')
        }
    }, [loading])

    const lazyLoaded = useRef(false)

    useEffect(() => {
        if(lazyLoaded.current) return;

        const cleanup = setTimeout(() => {
            lazyLoaded.current = true
            const entries = Object.entries(backgroundLoadPayloads).filter(([key]) => cmsPagesDataCollection.has(key) === false)

            const recursiveCall = async (index: number) => {
                if(index >= entries.length) return;

                const [key, value] = entries[index]

                const response = await getCMSData.call({
                    data: {
                        cmsPages: value,
                    },
                }) 

                if(response.data.cmsPages) {
                    cmsPagesDataCollection.set(key, response.data.cmsPages)
                }

                recursiveCall(index + 1)
            }

            recursiveCall(0)
        }, 3000)

        return () => clearTimeout(cleanup)
    }, [cmsPagesPayload, pathname])

    const cmsPages = cmsPagesDataCollection.get(pathname) || emptyArray

    const mainPage = cmsPages.filter((item) => item.identifier !== 'footer')[0]

    return (
		<CMSContext.Provider value={{ cmsPagesPayload, setCMSPagesPayload, cmsPages }}>
            <Helmet>
                <title>{mainPage?.pageTitle}</title>
                <meta name="description" content={mainPage?.metaDescription || ''} />
                <meta name="keywords" content={mainPage?.keywords || ''} />
            </Helmet>
            {loading && (
                <PageLoader />
            )}
            {children}
		</CMSContext.Provider>
	);
}

export default CMSProvider;