import branding from "@config/branding"
import { useAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAPI"
import React from "react"
import { trackingkey } from "./useTracking"

export const useCTACalendlyPop = () => {
    const requestCTA = useAPI('/public/WebsiteCTA')
    const requestWebsiteView = useAPI('/public/WebsiteCTA')
    const extractCTA = (cta: string) => {
        const ctaRegex = /^\[cta\((.*)\)\]$/
        const matches = cta.trim().match(ctaRegex)
        return matches?.[1]
    }

    const ctaAction = (cta: string) => async (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        const ctaDescription = extractCTA(cta) || ''
        
        callNow(ctaDescription, {
            // name: ctaDescription,
            // email: ctaDescription,
            // customAnswers: {
            //     a1: ctaDescription,
            // },
        });
    }

    const callNow = (ctaDescription: string, prefill?: Record<string, any>) => {
        requestCTA.call({
            data: {
                "accessKey": branding.accessKey,
                "trackingIdent": localStorage.getItem(trackingkey),
                "ctaDescription": ctaDescription
            }
        })
        //@ts-ignore
        window.Calendly.initPopupWidget({
            url: 'https://calendly.com/andy-charters-techanywhere/30-min-techanywhere?hide_gdpr_banner=1',
            prefill
        })
    }

    return {
        isCTA: (cta: string) => !!extractCTA(cta),
        ctaAction,
        callNow
    }
}