import React, { useEffect, useLayoutEffect } from 'react'
import Container from 'react-bootstrap/Container'
import PageLoader from "@components/Loader"
import { useBrandingScreenTitle } from '@hooks/useBrandingScreenTitle'
import browserAgent from '@whitecobalt/tungsten/utils/browserAgent'
import { useAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAPI"
import { Link, useHistory } from "react-router-dom"
import CMSPage from "@components/CMSProvider/CMSPage"
import CMSFieldGroup from "@components/CMSProvider/CMSFieldGroup"
import CMSField from "@components/CMSProvider/CMSField"
import { CMSFieldType } from "@types"
import './index.scss'

interface NotFoundProps {
}

const NotFound: React.FunctionComponent<NotFoundProps> = ({}) => {
    const history = useHistory()
    const getRedirect = useAPI<{redirect: string | null; permanent: boolean}>('/api/CMSRedirect/get', { method: 'GET' })

    useEffect(() => {
        getRedirect.call({
            params: {
                "URL": window.location.pathname
            }
        }).then((response) => {
            const redirectURL = response.data.redirect
            const permanent = response.data.permanent
            if(!redirectURL) return; 

            if(redirectURL.startsWith('http')) {

                if(permanent) {
                    return window.location.replace(redirectURL)
                }

                return window.location.assign(redirectURL)
            }

            if(permanent) {
                return history.replace(redirectURL)
            }

            history.push(redirectURL)
        })
    }, [])

    useLayoutEffect(() => {
        const root = document.getElementById('root')

        root?.classList.add('no-half-bg')

        return () => root?.classList.remove('no-half-bg')
    }, [])

    useBrandingScreenTitle(getRedirect.loading ? 'Loading...' : '404 - page not found')

    if(getRedirect.loading) {
		return <PageLoader />
	}
    
    return (
        <CMSPage identifier="404.page">
        <div className="error-page" style={{backgroundImage: 'url(/media/bg/exhausted.jpg)'}}>
            <CMSFieldGroup identifier="main.content" description="Main content">
                <Container className="error-page-content">
                    <CMSField
                        identifier="main.content.title"
                        description="Main content title"
                        cmsPageFieldTypeID={CMSFieldType.Text}
                        showDefault
                    >
                        {(text = "Oops!") => <h1>{text}</h1>}
                    </CMSField>
                    <CMSField
                        identifier="main.content.subtitle"
                        description="Main content subtitle"
                        cmsPageFieldTypeID={CMSFieldType.Text}
                        showDefault
                    >
                        {(text = "It seems we’ve lost our way!") => <h2>{text}</h2>}
                    </CMSField>
                    <CMSField
                        identifier="main.content.description"
                        description="Main content description"
                        cmsPageFieldTypeID={CMSFieldType.FullHTML}
                        showDefault
                    >
                        {(text = `<p>
                            Like a developer without coffee, this page isn’t functioning as expected.
                            Whether it’s missing or just hiding in the digital ether, we can’t find
                            what you’re looking for.
                        </p>
                        <p>
                            But fear not! TechAnywhere is here to guide you back on track (it’s what
                            we do best, after all).
                        </p>`) => (
                            <div dangerouslySetInnerHTML={{__html: text}} />
                        )}
                    </CMSField>
                </Container>
            </CMSFieldGroup>
            <CMSFieldGroup identifier="actions" description="Actions">
                <div className="error-page-actions">
                    <div>
                        <div className="d-flex justify-content-center flex-wrap gap-3">
                            <div className="d-flex flex-column align-items-center px-5">
                                <CMSField
                                    identifier="action.one.description"
                                    description="Action One Description"
                                    cmsPageFieldTypeID={CMSFieldType.Text}
                                    showDefault
                                >
                                    {(text = "Return to home") => (
                                        <CMSField
                                            identifier="action.one.link"
                                            description="Action One Link"
                                            cmsPageFieldTypeID={CMSFieldType.Text}
                                            showDefault
                                        >
                                            {(link = "/") => link.startsWith('/') ? (
                                                <Link to={link} className="btn btn-primary btn-custom">
                                                    {text}
                                                </Link>
                                            ) : (
                                                <a href={link} target="_blank" rel="noreferrer" className="btn btn-primary btn-custom">
                                                    {text}
                                                </a>
                                            )}
                                        </CMSField>
                                    )}
                                </CMSField>
                                <CMSField
                                    identifier="action.one.helptext"
                                    description="Action One Helptext"
                                    cmsPageFieldTypeID={CMSFieldType.Text}
                                    showDefault
                                >
                                    {(text = "It's safer there") => <p>{text}</p>}
                                </CMSField>
                            </div>
                            <div className="d-flex flex-column align-items-center px-5">
                                <CMSField
                                    identifier="action.two.description"
                                    description="Action Two Description"
                                    cmsPageFieldTypeID={CMSFieldType.Text}
                                    showDefault
                                >
                                    {(text = "Get in touch") => (
                                        <CMSField
                                            identifier="action.two.link"
                                            description="Action Two Link"
                                            cmsPageFieldTypeID={CMSFieldType.Text}
                                            showDefault
                                        >
                                            {(link = "/contact") => link.startsWith('/') ? (
                                                <Link to={link} className="btn btn-primary btn-custom">
                                                    {text}
                                                </Link>
                                            ) : (
                                                <a href={link} target="_blank" rel="noreferrer" className="btn btn-primary btn-custom">
                                                    {text}
                                                </a>
                                            )}
                                        </CMSField>
                                    )}
                                </CMSField>
                                <CMSField
                                    identifier="action.two.helptext"
                                    description="Action Two Helptext"
                                    cmsPageFieldTypeID={CMSFieldType.Text}
                                    showDefault
                                >
                                    {(text = "If you need some human assistance, our team is only a click away") => <p>{text}</p>}
                                </CMSField>
                            </div>
                        </div>
                        <CMSField
                            identifier="action.message"
                            description="Action Last Message"
                            cmsPageFieldTypeID={CMSFieldType.Text}
                            showDefault
                        >
                            {(text = "Or, you know, you could try turning it off and on again. That always works, right?") => <p className="last-message">{text}</p>}
                        </CMSField>
                    </div>
                </div>
            </CMSFieldGroup>
        </div>
        </CMSPage>
    )
}

export default NotFound