import React from 'react';
import { useThemeStore } from '@services/store/theme';
import Footer from "./Footer";
import Header from "./Header";
import CMSProvider from "@components/CMSProvider";

interface Layout {

}

const Layout: React.FunctionComponent<Layout> = ({ children }) => {
    const [theme] = useThemeStore()

	return (
		<CMSProvider>
            <Header />
            <main>
                {children}
            </main>
            <Footer />
		</CMSProvider>
	);
}

export default Layout;