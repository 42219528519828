import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ServicesConfig from '@whitecobalt/tungsten/ServicesConfig';
import RememberTableState from '@whitecobalt/tungsten/esm/components/Grid/RememberTableState';
import Application from '@app/index';
import '@styles/main.scss';
import '@utils/customValidation';
const rootElement = document.getElementById("root");

const render = rootElement?.hasChildNodes() ? ReactDOM.hydrate : ReactDOM.render;
render(
	<React.StrictMode>
		<ServicesConfig>
			<BrowserRouter>
				<RememberTableState>
					<Application />
				</RememberTableState>
			</BrowserRouter>
		</ServicesConfig>
	</React.StrictMode>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
// 	onSuccess: (registration) => {
// 		const { scope } = registration;
// 		if (scope) {
// 			console.log('Service worker registration succeeded. Scope is:', scope);
// 		} else {
// 			console.log('Service worker registration succeeded.');
// 		}
// 	},
// 	onUpdate: (registration) => {
// 		const { update } = registration;
// 		if (update) {
// 			update();
// 		}
// 	},
// });

serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
