import React, { Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConditionalRoute from "@whitecobalt/tungsten/ConditionalRoute";
import ErrorBoundary from "@whitecobalt/tungsten/ErrorBoundary";
import { useSession, SessionActionType } from "@whitecobalt/tungsten/Session";
import PageLoader from "@components/Loader";
import branding from "@config/branding";
import ErrorPage404 from "./pages/Error/404";
import AuthRoutes from "./pages/Auth";
import CustomPublic from "./pages/CustomPublic";
import Layout from "@components/Layout";
import UserLayout from "@components/UserLayout";
import { About, ConfirmSubscription, Contact, Developers, FAQs, Home, HowItWorks, InsightsRedirect, Packages, Privacy, Terms, AntiSlavery, Testimonials } from "./pages";

const Routes = () => {
    const [{user, loading }, dispatch] = useSession();
	const hasAuthenthication = !!user
    
	const handleRedirect = (redirected: boolean, props: any) => {
		if (redirected) {
			dispatch({
				type: SessionActionType.SET_REDIRECT,
				payload: props.location.pathname + props.location.search
			})
		}
    }

    useEffect(() => {
        document.body.setAttribute('data-auth', (!!user).toString())
    }, [user])

    if(loading) return (
		<PageLoader />
    )
    
	return (
        <Switch>
            <Route path="/auth" component={AuthRoutes}/>
            {/* <Route path="/public" component={CustomPublic}/> */}
            <Route
                path="/">
                <Layout>
                    {/**@ts-ignore */}
                    <ErrorBoundary>
                        <Suspense 
                            fallback={(
                                <PageLoader />
                            )}
                        >
                            <Switch>
                                <Route path="/" exact component={Home} />
                                <Route path="/about-us" component={About} />
                                <Route path="/developers" component={Developers} />
                                <Route path="/packages" component={Packages} />
                                <Route path="/how-it-works" component={HowItWorks} />
                                {/* <Route path="/testimonials" component={Testimonials} /> */}
                                <Route path="/faqs" component={FAQs} />
                                <Route path="/insights" component={InsightsRedirect} />
                                <Route path="/contact" component={Contact} />
                                <Route path="/anti-slavery-policy" component={AntiSlavery} />
                                <Route path="/terms-and-conditions" component={Terms} />
                                <Route path="/privacy-policy" component={Privacy} />
                                <Route path="/confirm-subscription" component={ConfirmSubscription} />
                                <Route path="*" component={ErrorPage404}/>
                            </Switch>
                        </Suspense>
                    </ErrorBoundary>
                </Layout>
            </Route>
        </Switch>
	);
}

export default Routes