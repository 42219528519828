import React, { useEffect, useMemo, useRef } from "react"
import { Nav, Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
import { useAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAPI"
import "./index.scss"
import {
  sortData,
  SortOrder,
} from "@whitecobalt/tungsten/esm/common/utils/data"
import { useCTACalendlyPop } from "@hooks/useCTACalendlyPop"
import Button from "@whitecobalt/tungsten/esm/components/Button"
import Icon from "@whitecobalt/tungsten/esm/components/Icon"

type MenuItemData = {
  title: string
  url: string
  newWindow: boolean
  listOrder: number
}

type MenuItemResponse = {
  success: boolean
  menuItems: MenuItemData[]
}

interface Header {}

const Header: React.FunctionComponent<Header> = () => {
  const { callNow } = useCTACalendlyPop()
  const getMenuItems = useAPI<MenuItemResponse>("/api/WebMenu/data", {
    method: "GET",
  })

  useEffect(() => {
    getMenuItems.call({
      params: {
        MenuID: 2,
      },
    })
  }, [])

  const items = useMemo(
    () =>
      sortData(
        { order: SortOrder.ASC, orderBy: "listOrder" },
        getMenuItems.response?.menuItems || []
      ),
    [getMenuItems.response]
  )

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const header = ref.current
    if (header) {
      const handler = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        if (scrollTop > 200 && header.classList.contains("sticky") === false) {
          header.classList.add("sticky")
        } else if(header.classList.contains("sticky") && scrollTop <= 1) {
          header.classList.remove("sticky")
        }
      }
      document.addEventListener("scroll", handler)

      return () => {
        document.removeEventListener("scroll", handler)
      }
    }
  }, [])

  return (
    <header ref={ref} className="header-section">
      <Navbar bg="primary" expand="lg" variant="dark">
        <div className="container-fluid container-2xl">
          <Navbar.Brand as={Link} to="/">
            <img className="brand-logo" src="/media/logos/logo.png" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="nav-collapse" />
          <Navbar.Collapse id="nav-collapse">
            <Nav className="flex-wrap ml-auto my-2 my-lg-0">
              {items.map((item, index) => (
                <Nav.Link
                  as={item.newWindow ? "a" : Link}
                  {...{
                    [item.newWindow ? "href" : "to"]: item.url,
                    target: item.newWindow ? "_blank" : "_self",
                  }}
                  className="text-center"
                  key={index}
                >
                  {item.title}
                </Nav.Link>
              ))}
              <Button variant="primary" onClick={() => callNow('Schedule A Call')} className="ml-3" style={{ borderRadius: 10 }}>
                <Icon name="phone-volume"/> Schedule A Call
              </Button>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  )
}

export default Header
