import { lazy } from 'react'

export const Landing =  lazy(() => import('./Landing'))
export const Home =  lazy(() => import('./Home'))
export const About =  lazy(() => import('./About'))
export const Developers =  lazy(() => import('./Developers'))
export const Packages =  lazy(() => import('./Packages'))
export const HowItWorks =  lazy(() => import('./HowItWorks'))
export const FAQs =  lazy(() => import('./FAQs'))
export const Testimonials =  lazy(() => import('./Testimonials'))
export const Insights =  lazy(() => import('./Insights'))
export const IndividualInsights =  lazy(() => import('./IndividualInsights'))
export const InsightsRedirect =  lazy(() => import('./InsightsRedirect'))
export const Contact =  lazy(() => import('./Contact'))
export const Sitemap =  lazy(() => import('./Sitemap'))
export const Privacy =  lazy(() => import('./Privacy'))
export const Terms =  lazy(() => import('./Terms'))
export const AntiSlavery =  lazy(() => import('./AntiSlavery'))
export const ConfirmSubscription =  lazy(() => import('./ConfirmSubscription'))