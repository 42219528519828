import { useAuthAPI } from "@whitecobalt/tungsten/esm/common/hooks/useAuthAPI"
import { trackingkey } from "./useTracking"
import { FormEvent } from "formydable"
import branding from "@config/branding"

export const useNewsLetterSubscribe = () => {

    const request = useAuthAPI('/public/WebsiteNewsletter/subscribe')

    const handler = (event: FormEvent) => {
        if(!event.isReady()) return;

        const FORM_DATA = event.json()
        const trackingIdent = localStorage.getItem(trackingkey)
        
        return request.call({
            data: {
                "emailAddress": FORM_DATA.emailAddress,
                "trackingIdent": trackingIdent,
                "accessKey": branding.accessKey
            }
        })
    }

    return {
        ...request,
        handler
    }
}