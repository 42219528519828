import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useCMS } from "..";
import { objectByIndex } from "@whitecobalt/tungsten/esm/common/utils/data";

export type CMSPayloadFieldType = {
    cmsPageFieldTypeID: number,
    groupIdent: string | null,
    identifier: string,
    description: string
}

export type CMSPayloadGroupType = {
    identifier: string,
    description: string
}

const CMSPageContext = createContext<{
    cmsPageField: Record<any, {
        identifier: string;
        value: string;
    }>;
    cmsPageFieldsPayload: CMSPayloadFieldType[];
    setCMSPageFieldsPayload: React.Dispatch<React.SetStateAction<CMSPayloadFieldType[]>>;
    cmsPageGroupsPayload: CMSPayloadGroupType[];
    setCMSPageGroupsPayload: React.Dispatch<React.SetStateAction<CMSPayloadGroupType[]>>;
} | null>(null)

export const useCMSPage = () => {
    const cmsPageContext = useContext(CMSPageContext)
    if(!cmsPageContext) {
        throw new Error('useCMSPage must be used within a CMSProvider')
    }
    return cmsPageContext
}

interface CMSPageProps {
    identifier: string;
    partialPage?: boolean;
}

const CMSPage: React.FunctionComponent<CMSPageProps> = ({ identifier, partialPage, children }) => {
    const { cmsPages, setCMSPagesPayload } = useCMS()
    const [ cmsPageFieldsPayload, setCMSPageFieldsPayload ] = useState<CMSPayloadFieldType[]>([])
    const [ cmsPageGroupsPayload, setCMSPageGroupsPayload ] = useState<CMSPayloadGroupType[]>([])

    const cmsPageField = useMemo(() => objectByIndex("identifier", cmsPages.find((page) => page.identifier === identifier)?.fields || []), [cmsPages, identifier])

    useEffect(() => {
        setCMSPagesPayload((prev) => [
            ...prev,
            {
                "identifier": identifier,
                "partialPage": partialPage ?? false,
                "url": partialPage ? null : window.location.pathname,
                "fields": cmsPageFieldsPayload,
                "groups": cmsPageGroupsPayload
            }
        ])

        return () => {
            setCMSPagesPayload((prev) => prev.filter((page) => page.identifier !== identifier))
        }
    }, [identifier, partialPage, cmsPageFieldsPayload, cmsPageGroupsPayload])

	return (
		<CMSPageContext.Provider value={{ cmsPageField, cmsPageFieldsPayload, setCMSPageFieldsPayload, cmsPageGroupsPayload, setCMSPageGroupsPayload }}>
            {children}
		</CMSPageContext.Provider>
	);
}

export default CMSPage;