export type Prettify<T> = {
  [P in keyof T]: T[P]
}

export type GridType<T> = {
  grid: {
    items: T[];
    totalCount: number;
  }
}
export interface UserProps {
    id: number;
    firstname: string;
    surname: string;
    emailAddress: string;
    adminUser: boolean;
    disabled: boolean;
    invited: boolean;
    jobTitle: null | string;
    lastLogin: string;
    lockedOut: boolean;
    jobRoleIDs: number[]
    jobRoles: any[];
    loginMFAEnabled: boolean;
    userTypeIDs: number[];
    accreditationHubUpdates: boolean;
    marketingEmailOptIn: boolean;
    hasNameChangedInTheLastFiveYears: boolean;
    mobileCountry: { 
        value: null | string 
    };
    mobileCountryID: null | number;
    mobileNumber: null | string;
    homeNumber: null | string;
    workNumber: null | string;
    dateOfBirth: null | string;
    nationalityID: number | null;
    occupation: string | null;
    county: string | null;
    niNumber: string | null;
    programIDs: number[];
    permissionIDs: number[];
    permissionPresetID: null | number;
    phoneNumber: null | string;
    title: null | string;
    photo: null | string;
    adminOrganisationIDs: number[];
    organisationIDs: number[];
    organisations: OrganisationProps[]
    applicationAreaIDs: number[];
    applicationAreas: ApplicationAreaProps[];
    address1: string;
    address2: string;
    address3: string;
    town: string | null;
    postcode: string;
    previousUserNameIDs: number[];
    previousName: null | string;
    previousNameEffectiveDate: null | string;
    previousUserNames: {name: string, effectiveDate: string}[];
    userAddressIDs: number[];
}

export interface OrganisationProps {
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  defaultVoiceID: number;
  adminUserIDs: number[];
  companyLogo: null | string;
  companyName: string;
  contactEmailAddress: null | string;
  contactNumber: null | string;
  countryID: number;
  id: number;
  lastNotificationDateTime: null | string;
  organisationTypeIDs: null | number[];
  postcode: string;
  registrationNumber: null | string;
  vatNumber: null | string;
  companyLogoURL?: string;
  discount: number;
  showPrices: boolean;
  pricingRegionID: number;
  canPayOnAccount: boolean;
}

export interface ApplicationAreaProps {
  allowRegistration: boolean;
  baseURL: string;
  confirmEmailTemplate: string;
  description: string;
  enableMFA: boolean;
  id: number;
  inviteUserTemplate: string;
  maxPasswordAge: null | number;
  mfaTemplate: string;
  passwordMinLength: null | number;
  passwordMinStrength: null | number;
  passwordResetTemplate: string;
  requireEmailConfirmation: boolean;
}

export enum CMSFieldType {
  Text = 1,
  MiniHTML = 2,
  FullHTML = 3,
  Image= 4,
  Number = 5,
  Date = 6,
  Hyperlink = 7
}