const footerPayload = {
  identifier: "footer",
  partialPage: true,
  url: null,
  fields: [
    {
      "identifier": "footer.form.text",
      "groupIdent": null,
      "cmsPageFieldTypeID": 2,
      "description": "Form Text"
    },
    {
      "identifier": "footer.form.button.text",
      "groupIdent": null,
      "cmsPageFieldTypeID": 1,
      "description": "Form Submit Button Text"
    },
    {
      "identifier": "footer.form.bg.text",
      "groupIdent": null,
      "cmsPageFieldTypeID": 1,
      "description": "Form Submit Background Text"
    },
    {
      "identifier": "form.success.message",
      "groupIdent": null,
      "cmsPageFieldTypeID": 3,
      "description": "Form Success Message"
    },
    {
      "identifier": "form.error.message",
      "groupIdent": null,
      "cmsPageFieldTypeID": 3,
      "description": "Form Error Message"
    },
    {
      "identifier": "footer.linkedin.logo",
      "groupIdent": null,
      "cmsPageFieldTypeID": 4,
      "description": "LinkedIn Logo"
    },
    {
      "identifier": "footer.linkedin.url",
      "groupIdent": null,
      "cmsPageFieldTypeID": 7,
      "description": "LinkedIn URL"
    },
    {
      "identifier": "footer.twitter.logo",
      "groupIdent": null,
      "cmsPageFieldTypeID": 4,
      "description": "Twitter Logo"
    },
    {
      "identifier": "footer.twitter.url",
      "groupIdent": null,
      "cmsPageFieldTypeID": 7,
      "description": "Twitter URL"
    },
    {
      "identifier": "footer.instagram.logo",
      "groupIdent": null,
      "cmsPageFieldTypeID": 4,
      "description": "Instagram Logo"
    },
    {
      "identifier": "footer.instagram.url",
      "groupIdent": null,
      "cmsPageFieldTypeID": 7,
      "description": "Instagram URL"
    },
    {
      "identifier": "footer.terms",
      "groupIdent": null,
      "cmsPageFieldTypeID": 7,
      "description": "Terms & Conditions URL"
    },
    {
      "identifier": "footer.privacy.policy",
      "groupIdent": null,
      "cmsPageFieldTypeID": 7,
      "description": "Privacy Policy URL"
    },
    {
      "identifier": "footer.anti-slavery.url",
      "groupIdent": null,
      "cmsPageFieldTypeID": 7,
      "description": "Anti-Slavery Policy URL"
    },
    {
      "identifier": "footer.copyright",
      "groupIdent": null,
      "cmsPageFieldTypeID": 1,
      "description": "Footer Copyright"
    },
    {
      "identifier": "footer.others",
      "groupIdent": null,
      "cmsPageFieldTypeID": 1,
      "description": "Footer Others"
    }
  ],
  groups: [],
}

const homePayload = [
  footerPayload,
  {
    identifier: "home",
    partialPage: false,
    url: "/",
    fields: [
      {
        "identifier": "hero.title",
        "groupIdent": "hero",
        "cmsPageFieldTypeID": 2,
        "description": "Hero Title"
      },
      {
        "identifier": "hero.description",
        "groupIdent": "hero",
        "cmsPageFieldTypeID": 2,
        "description": "Hero Description"
      },
      {
        "identifier": "hero.image",
        "groupIdent": "hero",
        "cmsPageFieldTypeID": 4,
        "description": "Hero Image"
      },
      {
        "identifier": "banner.description",
        "groupIdent": null,
        "cmsPageFieldTypeID": 2,
        "description": "Banner Description"
      },
      {
        "identifier": "interested.title",
        "groupIdent": "interested",
        "cmsPageFieldTypeID": 1,
        "description": "Interested Title"
      },
      {
        "identifier": "interested.description",
        "groupIdent": "interested",
        "cmsPageFieldTypeID": 2,
        "description": "Interested Description"
      },
      {
        "identifier": "interested.button.description",
        "groupIdent": "interested",
        "cmsPageFieldTypeID": 1,
        "description": "Interested Button Description"
      },
      {
        "identifier": "interested.button.link",
        "groupIdent": "interested",
        "cmsPageFieldTypeID": 7,
        "description": "Interested Button Link"
      },
      {
        "identifier": "git.title",
        "groupIdent": "git",
        "cmsPageFieldTypeID": 2,
        "description": "Get in touch - Title 1"
      },
      {
        "identifier": "git.description",
        "groupIdent": "git",
        "cmsPageFieldTypeID": 3,
        "description": "Get in touch - Description 1"
      },
      {
        "identifier": "git.title.2",
        "groupIdent": "git",
        "cmsPageFieldTypeID": 1,
        "description": "Get in touch - Title 2"
      },
      {
        "identifier": "git.description.2",
        "groupIdent": "git",
        "cmsPageFieldTypeID": 3,
        "description": "Get in touch - Description 2"
      },
      {
        "identifier": "git.button.description",
        "groupIdent": "git",
        "cmsPageFieldTypeID": 1,
        "description": "Get in touch - Button Description"
      },
      {
        "identifier": "git.button.link",
        "groupIdent": "git",
        "cmsPageFieldTypeID": 7,
        "description": "Get in touch - Button Link"
      },
      {
        "identifier": "git.image2",
        "groupIdent": "git",
        "cmsPageFieldTypeID": 4,
        "description": "Get in touch - Image 2"
      },
      {
        "identifier": "cta.text.one",
        "groupIdent": "cta",
        "cmsPageFieldTypeID": 2,
        "description": "Call to Action - Text 1"
      },
      {
        "identifier": "cta.button.one.description",
        "groupIdent": "cta",
        "cmsPageFieldTypeID": 1,
        "description": "Call to Action - Button 1 Description"
      },
      {
        "identifier": "cta.button.one.link",
        "groupIdent": "cta",
        "cmsPageFieldTypeID": 7,
        "description": "Call to Action - Button 1 Link"
      },
      {
        "identifier": "cta.text.two",
        "groupIdent": "cta",
        "cmsPageFieldTypeID": 2,
        "description": "Call to Action - Text 2"
      },
      {
        "identifier": "cta.button.two.description",
        "groupIdent": "cta",
        "cmsPageFieldTypeID": 1,
        "description": "Call to Action - Button 2 Description"
      },
      {
        "identifier": "cta.button.two.link",
        "groupIdent": "cta",
        "cmsPageFieldTypeID": 7,
        "description": "Call to Action - Button 2 Link"
      }
    ],
    groups: [
      {
        identifier: "hero",
        description: "Hero",
      },
      {
        identifier: "interested",
        description: "Interested",
      },
      {
        identifier: "git",
        description: "Get in touch",
      },
      {
        identifier: "cta",
        description: "Call to Action",
      },
    ],
  },
]

const aboutPayload = [
  footerPayload,
  {
    identifier: "about",
    partialPage: false,
    url: "/about-us",
    fields: [
      {
        identifier: "hero.title",
        groupIdent: "hero",
        cmsPageFieldTypeID: 1,
        description: "Hero Title",
      },
      {
        identifier: "hero.description",
        groupIdent: "hero",
        cmsPageFieldTypeID: 2,
        description: "Hero Description",
      },
      {
        identifier: "hero.image",
        groupIdent: "hero",
        cmsPageFieldTypeID: 4,
        description: "Hero Image",
      },
      {
        identifier: "content.left.description",
        groupIdent: "content",
        cmsPageFieldTypeID: 3,
        description: "Content Left Description",
      },
      {
        identifier: "content.right.description",
        groupIdent: "content",
        cmsPageFieldTypeID: 3,
        description: "Content Right Description",
      },
      {
        identifier: "first.cofounder.image",
        groupIdent: "team",
        cmsPageFieldTypeID: 4,
        description: "First Co-founder Image",
      },
      {
        identifier: "first.cofounder.name",
        groupIdent: "team",
        cmsPageFieldTypeID: 1,
        description: "First Co-founder Name",
      },
      {
        identifier: "first.cofounder.position",
        groupIdent: "team",
        cmsPageFieldTypeID: 1,
        description: "First Co-founder Position",
      },
      {
        identifier: "first.cofounder.description",
        groupIdent: "team",
        cmsPageFieldTypeID: 3,
        description: "First Co-founder Name",
      },
      {
        identifier: "first.cofounder.btn.text",
        groupIdent: "team",
        cmsPageFieldTypeID: 1,
        description: "First Co-founder Button Text",
      },
      {
        identifier: "first.cofounder.btn.link",
        groupIdent: "team",
        cmsPageFieldTypeID: 7,
        description: "First Co-founder Button Link",
      },
      {
        identifier: "second.cofounder.image",
        groupIdent: "team",
        cmsPageFieldTypeID: 4,
        description: "Second Co-founder Image",
      },
      {
        identifier: "second.cofounder.name",
        groupIdent: "team",
        cmsPageFieldTypeID: 1,
        description: "Second Co-founder Name",
      },
      {
        identifier: "second.cofounder.position",
        groupIdent: "team",
        cmsPageFieldTypeID: 1,
        description: "Second Co-founder Position",
      },
      {
        identifier: "second.cofounder.description",
        groupIdent: "team",
        cmsPageFieldTypeID: 3,
        description: "Second Co-founder Name",
      },
      {
        identifier: "second.cofounder.btn.text",
        groupIdent: "team",
        cmsPageFieldTypeID: 1,
        description: "Second Co-founder Button Text",
      },
      {
        identifier: "second.cofounder.btn.link",
        groupIdent: "team",
        cmsPageFieldTypeID: 7,
        description: "Second Co-founder Button Link",
      },
    ],
    groups: [
      {
        identifier: "hero",
        description: "Hero",
      },
      {
        identifier: "content",
        description: "Main Content",
      },
      {
        identifier: "team",
        description: "The individuals",
      },
    ],
  },
]

const developersPayload = [
  footerPayload,
  {
    identifier: "developers",
    partialPage: false,
    url: "/developers",
    fields: [
      {
        identifier: "hero.title",
        groupIdent: "hero",
        cmsPageFieldTypeID: 2,
        description: "Hero Title",
      },
      {
        identifier: "hero.description",
        groupIdent: "hero",
        cmsPageFieldTypeID: 2,
        description: "Hero Description",
      },
      {
        identifier: "hero.image",
        groupIdent: "hero",
        cmsPageFieldTypeID: 4,
        description: "Hero Image",
      },
      {
        identifier: "reason.one.title",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 1,
        description: "Reason One Title",
      },
      {
        identifier: "reason.one.description",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 2,
        description: "Reason One Description",
      },
      {
        identifier: "reason.one.image",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 4,
        description: "Reason One Image",
      },
      {
        identifier: "reason.two.title",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 1,
        description: "Reason Two Title",
      },
      {
        identifier: "reason.two.description",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 2,
        description: "Reason Two Description",
      },
      {
        identifier: "reason.two.image",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 4,
        description: "Reason Two Image",
      },
      {
        identifier: "reason.three.title",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 1,
        description: "Reason Three Title",
      },
      {
        identifier: "reason.three.description",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 2,
        description: "Reason Three Description",
      },
      {
        identifier: "reason.three.image",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 4,
        description: "Reason Three Image",
      },
      {
        identifier: "reason.four.title",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 1,
        description: "Reason Four Title",
      },
      {
        identifier: "reason.four.description",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 2,
        description: "Reason Four Description",
      },
      {
        identifier: "reason.four.image",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 4,
        description: "Reason Four Image",
      },
      {
        identifier: "reason.five.title",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 1,
        description: "Reason Five Title",
      },
      {
        identifier: "reason.five.description",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 2,
        description: "Reason Five Description",
      },
      {
        identifier: "reason.five.image",
        groupIdent: "reasons",
        cmsPageFieldTypeID: 4,
        description: "Reason Five Image",
      },
      {
        identifier: "contact.title",
        groupIdent: "contact",
        cmsPageFieldTypeID: 1,
        description: "Contact Title",
      },
      {
        identifier: "contact.description",
        groupIdent: "contact",
        cmsPageFieldTypeID: 2,
        description: "Contact Description",
      },
      {
        identifier: "contact.box",
        groupIdent: "contact",
        cmsPageFieldTypeID: 3,
        description: "Contact In Box",
      },
      {
        identifier: "contact.last.description",
        groupIdent: "contact",
        cmsPageFieldTypeID: 2,
        description: "Contact Last Description",
      },
    ],
    groups: [
      {
        identifier: "hero",
        description: "Hero",
      },
      {
        identifier: "reasons",
        description: "Reasons to Join",
      },
      {
        identifier: "contact",
        description: "Contact",
      },
    ],
  },
]

const packagesPayload = [
  footerPayload,
  {
    identifier: "packages",
    partialPage: false,
    url: "/packages",
    fields: [
      {
        "identifier": "package.description",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 3,
        "description": "Package Description"
      },
      {
        "identifier": "package.option.one.quick.title",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option One Quick Title"
      },
      {
        "identifier": "package.option.one.summary",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option One Summary"
      },
      {
        "identifier": "package.option.one.text",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option One Anchor Text"
      },
      {
        "identifier": "package.option.one.anchor",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 7,
        "description": "Package Option One Anchor"
      },
      {
        "identifier": "package.option.two.quick.title",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option Two Quick Title"
      },
      {
        "identifier": "package.option.two.summary",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option Two Summary"
      },
      {
        "identifier": "package.option.two.text",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option Two Anchor Text"
      },
      {
        "identifier": "package.option.two.anchor",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 7,
        "description": "Package Option Two Anchor"
      },
      {
        "identifier": "package.option.three.quick.title",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option Three Quick Title"
      },
      {
        "identifier": "package.option.three.summary",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option Three Summary"
      },
      {
        "identifier": "package.option.three.text",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option Three Anchor Text"
      },
      {
        "identifier": "package.option.three.anchor",
        "groupIdent": "packages.quick",
        "cmsPageFieldTypeID": 7,
        "description": "Package Option Three Anchor"
      },
      {
        "identifier": "package.banner",
        "groupIdent": null,
        "cmsPageFieldTypeID": 2,
        "description": "Package Banner"
      },
      {
        "identifier": "package.option.one.anchor",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 7,
        "description": "Package Option One Anchor"
      },
      {
        "identifier": "package.option.one.title",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option One Title"
      },
      {
        "identifier": "package.option.one.subtitle",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option One Subtitle"
      },
      {
        "identifier": "package.option.one.description",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option One Description"
      },
      {
        "identifier": "package.option.one.first.benefit.img",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 4,
        "description": "Package Option One First Benefit Image"
      },
      {
        "identifier": "package.option.one.first.benefit.desc",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option One First Benefit Description"
      },
      {
        "identifier": "package.option.one.second.benefit.img",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 4,
        "description": "Package Option One Second Benefit Image"
      },
      {
        "identifier": "package.option.one.second.benefit.desc",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option One Second Benefit Description"
      },
      {
        "identifier": "package.option.one.third.benefit.img",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 4,
        "description": "Package Option One Third Benefit Image"
      },
      {
        "identifier": "package.option.one.third.benefit.desc",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option One Third Benefit Description"
      },
      {
        "identifier": "package.option.one.banner",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option One Banner"
      },
      {
        "identifier": "package.option.one.link",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 7,
        "description": "Package Option One Link"
      },
      {
        "identifier": "package.option.one.link.text",
        "groupIdent": "packages.one.details",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option One Link Text"
      },
      {
        "identifier": "package.option.two.anchor",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 7,
        "description": "Package Option Two Anchor"
      },
      {
        "identifier": "package.option.two.title",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option Two Title"
      },
      {
        "identifier": "package.option.two.subtitle",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option Two Subtitle"
      },
      {
        "identifier": "package.option.two.description",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option Two Description"
      },
      {
        "identifier": "package.option.two.first.benefit.img",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 4,
        "description": "Package Option Two First Benefit Image"
      },
      {
        "identifier": "package.option.two.first.benefit.desc",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option Two First Benefit Description"
      },
      {
        "identifier": "package.option.two.second.benefit.img",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 4,
        "description": "Package Option Two Second Benefit Image"
      },
      {
        "identifier": "package.option.two.second.benefit.desc",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option Two Second Benefit Description"
      },
      {
        "identifier": "package.option.two.third.benefit.img",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 4,
        "description": "Package Option Two Third Benefit Image"
      },
      {
        "identifier": "package.option.two.third.benefit.desc",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option Two Third Benefit Description"
      },
      {
        "identifier": "package.option.two.banner",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option Two Banner"
      },
      {
        "identifier": "package.option.two.link",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 7,
        "description": "Package Option Two Link"
      },
      {
        "identifier": "package.option.two.link.text",
        "groupIdent": "packages.two.details",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option Two Link Text"
      },
      {
        "identifier": "package.option.three.anchor",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 7,
        "description": "Package Option Three Anchor"
      },
      {
        "identifier": "package.option.three.title",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option Three Title"
      },
      {
        "identifier": "package.option.three.subtitle",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option Three Subtitle"
      },
      {
        "identifier": "package.option.three.description",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option Three Description"
      },
      {
        "identifier": "package.option.three.first.benefit.img",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 4,
        "description": "Package Option Three First Benefit Image"
      },
      {
        "identifier": "package.option.three.first.benefit.desc",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option Three First Benefit Description"
      },
      {
        "identifier": "package.option.three.second.benefit.img",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 4,
        "description": "Package Option Three Second Benefit Image"
      },
      {
        "identifier": "package.option.three.second.benefit.desc",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option Three Second Benefit Description"
      },
      {
        "identifier": "package.option.three.third.benefit.img",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 4,
        "description": "Package Option Three Third Benefit Image"
      },
      {
        "identifier": "package.option.three.third.benefit.desc",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option Three Third Benefit Description"
      },
      {
        "identifier": "package.option.three.banner",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 2,
        "description": "Package Option Three Banner"
      },
      {
        "identifier": "package.option.three.link",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 7,
        "description": "Package Option Three Link"
      },
      {
        "identifier": "package.option.three.link.text",
        "groupIdent": "packages.three.details",
        "cmsPageFieldTypeID": 1,
        "description": "Package Option Three Link Text"
      }
    ],
    groups: [
      {
        identifier: "packages.quick",
        description: "Packages Summary",
      },
      {
        identifier: "packages.one.details",
        description: "Package One Details",
      },
      {
        identifier: "packages.two.details",
        description: "Package Two Details",
      },
      {
        identifier: "packages.three.details",
        description: "Package Three Details",
      },
    ],
  },
]

const howItWorksPayload = [
  footerPayload,
  {
    identifier: "how-it-works",
    partialPage: false,
    url: "/how-it-works",
    fields: [
      {
        identifier: "hero.subtitle",
        groupIdent: "hero",
        cmsPageFieldTypeID: 1,
        description: "Hero Subtitle",
      },
      {
        identifier: "hero.description",
        groupIdent: "hero",
        cmsPageFieldTypeID: 2,
        description: "Hero Description",
      },
      {
        identifier: "hero.image",
        groupIdent: "hero",
        cmsPageFieldTypeID: 4,
        description: "Hero Image",
      },
      {
        identifier: "banner.countdown",
        groupIdent: "hero",
        cmsPageFieldTypeID: 2,
        description: "Banner Countdown",
      },
      {
        identifier: "banner.description",
        groupIdent: "hero",
        cmsPageFieldTypeID: 2,
        description: "Banner Description",
      },
      {
        identifier: "steps.title",
        groupIdent: "steps",
        cmsPageFieldTypeID: 1,
        description: "Steps Title",
      },
      {
        identifier: "step.one.title",
        groupIdent: "steps",
        cmsPageFieldTypeID: 1,
        description: "Step One Title",
      },
      {
        identifier: "step.one.description",
        groupIdent: "steps",
        cmsPageFieldTypeID: 3,
        description: "Step One Description",
      },
      {
        identifier: "step.two.title",
        groupIdent: "steps",
        cmsPageFieldTypeID: 1,
        description: "Step Two Title",
      },
      {
        identifier: "step.two.description",
        groupIdent: "steps",
        cmsPageFieldTypeID: 3,
        description: "Step Two Description",
      },
      {
        identifier: "step.three.title",
        groupIdent: "steps",
        cmsPageFieldTypeID: 1,
        description: "Step Three Title",
      },
      {
        identifier: "step.three.description",
        groupIdent: "steps",
        cmsPageFieldTypeID: 3,
        description: "Step Three Description",
      },
      {
        identifier: "step.four.title",
        groupIdent: "steps",
        cmsPageFieldTypeID: 1,
        description: "Step Four Title",
      },
      {
        identifier: "step.four.description",
        groupIdent: "steps",
        cmsPageFieldTypeID: 3,
        description: "Step Four Description",
      },
      {
        identifier: "step.five.title",
        groupIdent: "steps",
        cmsPageFieldTypeID: 1,
        description: "Step Five Title",
      },
      {
        identifier: "step.five.description",
        groupIdent: "steps",
        cmsPageFieldTypeID: 3,
        description: "Step Five Description",
      },
      {
        identifier: "step.six.title",
        groupIdent: "steps",
        cmsPageFieldTypeID: 1,
        description: "Step Six Title",
      },
      {
        identifier: "step.six.description",
        groupIdent: "steps",
        cmsPageFieldTypeID: 3,
        description: "Step Six Description",
      },
      {
        identifier: "step.seven.title",
        groupIdent: "steps",
        cmsPageFieldTypeID: 1,
        description: "Step Seven Title",
      },
      {
        identifier: "step.seven.description",
        groupIdent: "steps",
        cmsPageFieldTypeID: 3,
        description: "Step Seven Description",
      },
      {
        identifier: "step.eight.title",
        groupIdent: "steps",
        cmsPageFieldTypeID: 1,
        description: "Step Eight Title",
      },
      {
        identifier: "step.eight.description",
        groupIdent: "steps",
        cmsPageFieldTypeID: 3,
        description: "Step Eight Description",
      },
      {
        identifier: "step.nine.title",
        groupIdent: "steps",
        cmsPageFieldTypeID: 1,
        description: "Step Nine Title",
      },
      {
        identifier: "step.nine.description",
        groupIdent: "steps",
        cmsPageFieldTypeID: 3,
        description: "Step Nine Description",
      },
      {
        identifier: "ready.to.start.text",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "Ready to start Text",
      },
      {
        identifier: "ready.to.start.link",
        groupIdent: null,
        cmsPageFieldTypeID: 7,
        description: "Ready to start Link",
      },
    ],
    groups: [
      {
        identifier: "hero",
        description: "Hero",
      },
      {
        identifier: "steps",
        description: "Steps",
      },
    ],
  },
]

const testimonialsPayload = [
  footerPayload,
  {
    identifier: "testimonials",
    partialPage: false,
    url: "/testimonials",
    fields: [
      {
        identifier: "hero.title",
        groupIdent: "hero",
        cmsPageFieldTypeID: 1,
        description: "Hero Title",
      },
      {
        identifier: "hero.description",
        groupIdent: "hero",
        cmsPageFieldTypeID: 3,
        description: "Hero Description",
      },
      {
        identifier: "testimonials.One.image",
        groupIdent: "testimonials",
        cmsPageFieldTypeID: 4,
        description: "Testimonial One Image",
      },
      {
        identifier: "testimonials.One.description",
        groupIdent: "testimonials",
        cmsPageFieldTypeID: 3,
        description: "Testimonial One Description",
      },
      {
        identifier: "newsletter.title",
        groupIdent: "newsletter",
        cmsPageFieldTypeID: 1,
        description: "Newsletter Title",
      },
      {
        identifier: "newsletter.description",
        groupIdent: "newsletter",
        cmsPageFieldTypeID: 2,
        description: "Newsletter Description",
      },
      {
        identifier: "newsletter.button.text",
        groupIdent: "newsletter",
        cmsPageFieldTypeID: 1,
        description: "Newsletter Button Text",
      },
      {
        identifier: "newsletter.success.message",
        groupIdent: "newsletter",
        cmsPageFieldTypeID: 3,
        description: "Newsletter Success Message",
      },
      {
        identifier: "newsletter.error.message",
        groupIdent: "newsletter",
        cmsPageFieldTypeID: 3,
        description: "Newsletter Error Message",
      },
    ],
    groups: [
      {
        identifier: "hero",
        description: "Hero",
      },
      {
        identifier: "testimonials",
        description: "Testimonials",
      },
      {
        identifier: "newsletter",
        description: "Newsletter",
      },
    ],
  },
]

const faqsPayload = [
  footerPayload,
  {
    identifier: "faqs",
    partialPage: false,
    url: "/faqs",
    fields: [
      {
        identifier: "ready.to.start.text",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "Ready to start Text",
      },
      {
        identifier: "ready.to.start.link",
        groupIdent: null,
        cmsPageFieldTypeID: 7,
        description: "Ready to start Link",
      },
      {
        identifier: "big.question.1.image",
        groupIdent: null,
        cmsPageFieldTypeID: 4,
        description: "Big Question 1 Image",
      },
      {
        identifier: "big.question.2.image",
        groupIdent: null,
        cmsPageFieldTypeID: 4,
        description: "Big Question 2 Image",
      },
      {
        identifier: "big.question.3.image",
        groupIdent: null,
        cmsPageFieldTypeID: 4,
        description: "Big Question 3 Image",
      },
    ],
    groups: [],
  },
]

const insightsPayload = [footerPayload]

const contactPayload = [
  footerPayload,
  {
    identifier: "contact",
    partialPage: false,
    url: "/contact",
    fields: [
      {
        identifier: "success.message",
        groupIdent: null,
        cmsPageFieldTypeID: 3,
        description: "Success Message",
      },
      {
        identifier: "error.message",
        groupIdent: null,
        cmsPageFieldTypeID: 3,
        description: "Error Message",
      },
      {
        identifier: "form.submit",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "Form Submit",
      },
      {
        identifier: "contact.office.name.one",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "Contact Office Name 1",
      },
      {
        identifier: "contact.office.address.one",
        groupIdent: null,
        cmsPageFieldTypeID: 2,
        description: "Contact Office Address 1",
      },
      {
        identifier: "contact.office.name.two",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "Contact Office Name 2",
      },
      {
        identifier: "contact.office.address.two",
        groupIdent: null,
        cmsPageFieldTypeID: 2,
        description: "Contact Office Address 2",
      },
      {
        identifier: "contact.tel",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "Contact Tel",
      },
      {
        identifier: "contact.email",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "Contact Email",
      },
      {
        identifier: "registration.details.title",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "Registration Details Title",
      },
      {
        identifier: "company.number",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "Company Number",
      },
      {
        identifier: "vat.number",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "VAT Registration Number",
      },
    ],
    groups: [],
  },
]

const termsPayload = [
  footerPayload,
  {
    identifier: "terms",
    partialPage: false,
    url: "/terms-and-conditions",
    fields: [
      {
        identifier: "hero.title",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "Hero Title",
      },
      {
        identifier: "main.details",
        groupIdent: null,
        cmsPageFieldTypeID: 3,
        description: "Main Details",
      },
    ],
    groups: [],
  },
]

const privacyPayload = [
  footerPayload,
  {
    identifier: "privacy-policy",
    partialPage: false,
    url: "/privacy-policy",
    fields: [
      {
        identifier: "hero.title",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "Hero Title",
      },
      {
        identifier: "main.details",
        groupIdent: null,
        cmsPageFieldTypeID: 3,
        description: "Main Details",
      },
    ],
    groups: [],
  },
]

const antiSlaveryPayload = [
  footerPayload,
  {
    identifier: "anti-slavery",
    partialPage: false,
    url: "/anti-slavery-policy",
    fields: [
      {
        identifier: "hero.title",
        groupIdent: null,
        cmsPageFieldTypeID: 1,
        description: "Hero Title",
      },
      {
        identifier: "main.details",
        groupIdent: null,
        cmsPageFieldTypeID: 3,
        description: "Main Details",
      },
    ],
    groups: [],
  },
]

export const backgroundLoadPayloads: Record<string, any> = {
  "/": homePayload,
  "/about-us": aboutPayload,
  "/developers": developersPayload,
  "/packages": packagesPayload,
  "/how-it-works": howItWorksPayload,
  "/testimonials": testimonialsPayload,
  "/faqs": faqsPayload,
  "/insights": insightsPayload,
  "/contact": contactPayload,
  "/anti-slavery-policy": antiSlaveryPayload,
  "/terms-and-conditions": termsPayload,
  "/privacy-policy": privacyPayload,
}
