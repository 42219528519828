import React from 'react';
import CMSPage from "@components/CMSProvider/CMSPage";
import CMSField from "@components/CMSProvider/CMSField";
import { CMSFieldType } from "@types";
import Image from "@whitecobalt/tungsten/esm/components/Image";
import './index.scss';
import { useNewsLetterSubscribe } from "@hooks/useNewsLetterSubscribe";
import FormManager from "@whitecobalt/tungsten/esm/components/FormManager";
import Icon from "@whitecobalt/tungsten/esm/components/Icon";

interface Footer {

}

const Footer: React.FunctionComponent<Footer> = () => {
    const { handler, ...request } = useNewsLetterSubscribe()
    
	return (
        <CMSPage identifier="footer" partialPage>
            <footer className="footer-section text-light">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 d-flex justify-content-center align-items-center">
                            <img src="/media/logos/logo-circle-inverse.png" alt="Logo" className="img-fluid mb-5 mb-xl-3 d-none d-sm-block" />
                            <img src="/media/logos/logo-long-inverse.png" alt="Logo" className="img-fluid mb-5 mb-xl-3 d-block d-sm-none" />
                        </div>
                        <div className="col-xl-6 d-flex d-xl-block justify-content-center">
                            <div className="mb-4 mb-xl-0 text-center">
                                <CMSField 
                                    identifier="footer.form.text" 
                                    cmsPageFieldTypeID={CMSFieldType.MiniHTML}
                                    description="Form Text"
                                    showDefault
                                >
                                    {(text = "Sign up to the best newsletter on our website") => (
                                        <div dangerouslySetInnerHTML={{__html: text}}/>
                                    )}
                                </CMSField>
                                <FormManager onSubmit={handler}>
                                    <CMSField 
                                        identifier="footer.form.bg.text" 
                                        cmsPageFieldTypeID={CMSFieldType.Text}
                                        description="Form Submit Background Text"
                                        showDefault
                                    >
                                        {(text = "Email Address") => (
                                            <FormManager.Input type="email" 
                                                name="emailAddress" 
                                                className="form-control bg-transparent" 
                                                placeholder={text}
                                                barelyAppend={(
                                                    <CMSField 
                                                        identifier="footer.form.button.text" 
                                                        cmsPageFieldTypeID={CMSFieldType.Text}
                                                        description="Form Submit Button Text"
                                                        showDefault
                                                    >
                                                        {(text = "SUBMIT") => (
                                                            <button type="submit" disabled={request.loading} className="btn btn-outline-light">{text} {request.loading && <Icon name="circle-notch" spin/>}</button>
                                                        )}
                                                    </CMSField>
                                                    
                                                )}
                                            />
                                        )}
                                    </CMSField>
                                </FormManager>
                                <CMSField 
                                    cmsPageFieldTypeID={CMSFieldType.FullHTML} 
                                    identifier="form.success.message" 
                                    description="Form Success Message" 
                                >
                                    {(text) => request.response?.success === true && (
                                        <div className="alert alert-primary" dangerouslySetInnerHTML={{ __html: text || request.response?.message }}></div>
                                    )}
                                </CMSField>
                                <CMSField 
                                    cmsPageFieldTypeID={CMSFieldType.FullHTML} 
                                    identifier="form.error.message" 
                                    description="Form Error Message"
                                >
                                    {(text) => request.response?.success === false && (
                                        <div className="alert alert-warning" dangerouslySetInnerHTML={{ __html: text }}></div>
                                    )}
                                </CMSField>
                            </div>
                        </div>
                        <div className="col-xl-3 px-5">
                            <div className="social-links d-flex gap-2 gap-xl-5 mt-5 mt-sm-0 justify-content-center justify-content-xl-start">
                                <CMSField 
                                    identifier="footer.linkedin.url" 
                                    cmsPageFieldTypeID={CMSFieldType.Hyperlink}
                                    description="LinkedIn URL"
                                    showDefault
                                >
                                    {(link) => (
                                        <a href={link} target="_blank">
                                            <CMSField 
                                                identifier="footer.linkedin.logo" 
                                                cmsPageFieldTypeID={CMSFieldType.Image}
                                                description="LinkedIn Logo"
                                            >
                                                {(src) => (
                                                    <Image src={src} alt="LinkedIn Logo" />
                                                )}
                                            </CMSField>
                                        </a>
                                    )}
                                </CMSField>
                                <CMSField 
                                    identifier="footer.twitter.url" 
                                    cmsPageFieldTypeID={CMSFieldType.Hyperlink}
                                    description="Twitter URL"
                                    showDefault
                                >
                                    {(link) => (
                                        <a href={link} target="_blank">
                                            <CMSField 
                                                identifier="footer.twitter.logo" 
                                                cmsPageFieldTypeID={CMSFieldType.Image}
                                                description="Twitter Logo"
                                            >
                                                {(src) => (
                                                    <Image src={src} alt="Twitter Logo" />
                                                )}
                                            </CMSField>
                                        </a>
                                    )}
                                </CMSField>
                                <CMSField 
                                    identifier="footer.instagram.url" 
                                    cmsPageFieldTypeID={CMSFieldType.Hyperlink}
                                    description="Instagram URL"
                                    showDefault
                                >
                                    {(link) => (
                                        <a href={link} target="_blank">
                                            <CMSField 
                                                identifier="footer.instagram.logo" 
                                                cmsPageFieldTypeID={CMSFieldType.Image}
                                                description="Instagram Logo"
                                            >
                                                {(src) => (
                                                    <Image src={src} alt="Instagram Logo" />
                                                )}
                                            </CMSField>
                                        </a>
                                    )}
                                </CMSField>
                            </div>
                            <ul className="term-links list-unstyled mt-5 mt-sm-5 text-center text-xl-left">
                                <CMSField 
                                    identifier="footer.terms" 
                                    cmsPageFieldTypeID={CMSFieldType.Hyperlink}
                                    description="Terms & Conditions URL"
                                    showDefault
                                >
                                    {(link = "/terms-and-conditions") => (
                                        <li>
                                            <a href={link} className="text-white">Terms & Conditions</a>
                                        </li>
                                    )}
                                </CMSField>
                                <CMSField 
                                    identifier="footer.privacy.policy" 
                                    cmsPageFieldTypeID={CMSFieldType.Hyperlink}
                                    description="Privacy Policy URL"
                                    showDefault
                                >
                                    {(link = "/privacy-policy") => (
                                        <li>
                                            <a href={link} className="text-white">Privacy Policy</a>
                                        </li>
                                    )}
                                </CMSField>
                                <CMSField 
                                    identifier="footer.anti-slavery.url" 
                                    cmsPageFieldTypeID={CMSFieldType.Hyperlink}
                                    description="Anti-Slavery Policy URL"
                                    showDefault
                                >
                                    {(link = "/anti-slavery-policy") => (
                                        <li>
                                            <a href={link} className="text-white">Anti-Slavery Policy</a>
                                        </li>
                                    )}
                                </CMSField>
                            </ul>
                        </div>
                    </div>
                    <CMSField 
                        identifier="footer.copyright" 
                        cmsPageFieldTypeID={CMSFieldType.Text}
                        description="Footer Copyright"
                        showDefault
                    >
                        {(text = `©${new Date().getFullYear()}, TechAnywhere Ltd`) => (
                            <p className="mt-2 text-center">{text}</p>
                        )}
                    </CMSField>
                    <CMSField 
                        identifier="footer.others" 
                        cmsPageFieldTypeID={CMSFieldType.Text}
                        description="Footer Others"
                        showDefault
                    >
                        {(text = `VIN NUMBER`) => text && (
                            <p className="text-center">
                                <small>{text}</small>
                            </p>
                        )}
                    </CMSField>
                </div>
            </footer>
        </CMSPage>
	);
}

export default Footer;